import axios from "axios";
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import {
    FormControl,
    Input,
    InputLabel,
    Button as MuiButton,
    Paper,
    Typography
}   from "@material-ui/core";
import { spacing } from "@material-ui/system";

const Button = styled(MuiButton)(spacing);

const Wrapper = styled(Paper)`
    padding: ${props => props.theme.spacing(6)}px;

    ${props => props.theme.breakpoints.up("md")} {
      padding: ${props => props.theme.spacing(10)}px;
    }
`;

const request_url = '/signUp'
//const request_url = 'http://10.10.13.165:5000/SignUp'

class SignUp extends React.Component {
    state = {
        email : '',

        name : '',

        password : '',

        password_re : ''
    }

    handleChange = (event) => {
        this.setState({ [event.target.name] : event.target.value });
    }

    sendSignUp = async (email, name, password, password_re) => {
        const options = {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Accept': 'application/json', 
            },
            data: {
                'email': email,
                'name': name,
                'password': password,
                'password_re': password_re
            },
            url: request_url,
        };

        const response = await axios(options);
        
        if (response.status === 200) {            
            if (response.data.result === false) {
                alert("입력된 정보가 잘못되었습니다.");
            }
            else {
                alert("회원가입완료");
            }

            return response.data
        }
        return null;
    }

    handleClickButton = () => {
        this.sendSignUp(this.state.email, this.state.name, this.state.password, this.state.password_re).then(res => {
            if (res.result === true) {
                window.location.reload();
            }
            else {
                alert("입력된 정보가 잘못되었습니다.");
            }
        })
    }

    render() {
        return (
            <Wrapper>
            <Typography component="h1" variant="h4" align="center" gutterBottom>
                Get started
            </Typography>
            <Typography component="h2" variant="body1" align="center">
                Start creating the best possible user experience for you customers
            </Typography>
            <form>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="email">Email Address</InputLabel>
                    <Input 
                        id="email" 
                        name="email" 
                        value={this.state.email} 
                        autoFocus
                        onChange={this.handleChange}
                        autoComplete="off"
                        required/>
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="name">Name</InputLabel>
                    <Input 
                        id="name" 
                        name="name" 
                        value={this.state.name} 
                        onChange={this.handleChange}
                        required
                        />
                </FormControl>        
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="password">비밀번호</InputLabel>
                    <Input
                        name="password"
                        type="password"
                        id="password"
                        value={this.state.password}
                        onChange={this.handleChange}
                        autoComplete="false"
                        required
                    />
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="password">비밀번호 재입력</InputLabel>
                    <Input
                        name="password_re"
                        type="password"
                        id="password_re"
                        value={this.state.password_re}
                        onChange={this.handleChange}
                        autoComplete="off"
                        required
                    />
                </FormControl>
                <Button
                    component={Link}
                    to="/"
                    fullWidth
                    variant="contained"
                    color="primary"
                    mt={2}
                    onClick={this.handleClickButton}
                >
                Sign up
                </Button>
            </form>
            </Wrapper>
        );
    }  
}

export default SignUp;
