import axios from "axios";

import React from "react";

import MUIDataTable from "mui-datatables";

import styled from "styled-components";

import Loader from "../../components/Loader";

import {
    CardContent,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button as MuiButton,
    Breadcrumbs as MuiBreadcrumbs,
    Card as MuiCard,
    Divider as MuiDivider,
    Paper as MuiPaper,
    TextField as MuiTextField,
    Typography,
    IconButton,
} from "@material-ui/core";

import {
    Edit as EditIcon,
    ArrowRightAlt as ArrowRightAltIcon
} from "@material-ui/icons";


import { spacing } from "@material-ui/system";

import { request_url } from "../../index";

const Spacer = styled.div(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TextFieldSpacing = styled(MuiTextField)(spacing);

const TextField = styled(TextFieldSpacing)`
  width: 100%;
`;

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${props => props.theme.spacing(12)}px);
`;

const Wrapper = styled.div`
  padding: ${props => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent;

  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(80)}px;
  }
`;

class LogBuyCashSearchDetailCard extends React.Component {
    state = { 
        loading : true,

        data : {},

        dialog_name : '',

        dialog_type : -1,

        dialog_value : null,

        dialog_chage_value : null,
        
        dialog_open : false,
    };

    componentDidMount() {
        this.sendGetUserData(this.props.name, this.props.server).then(res => {
            this.setState({ loading : false, data : res });
        });
    }

    sendGetUserData = async (name, server) => {        
        const options = {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Accept': 'application/json',                  
            },
            data: {
                "name" : name,
                "server" : server
            },
            url: request_url + 'GetUserInfoDetail',

        };

        const response = await axios(options);
        if (response.status === 200) {
            return response.data
        }

        return [];
    }

    sendUpdateUserInfo = async (useridx, type, value) => {
        const options = {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Accept': 'application/json',                  
            },
            data: {
                "useridx" : useridx,
                "type" : type,
                "value" : value,
            },
            url: request_url + 'UpdateUserInfo',
        };

        const response = await axios(options);
        if (response.status === 200) {
            return response.data
        }

        return [];
    }
    
    handleChange = (event) => {
        this.setState({ [event.target.name] : event.target.value });
    }

    handleDialogClose = () => {
        this.setState({ dialog_open: false,  dialog_name : '', dialog_type : -1, dialog_value : null, dialog_chage_value : null });
    };

    handleDialogSave = () => {
        this.setState({ loading : true });

        this.sendUpdateUserInfo(this.props.name, this.state.dialog_type, this.state.dialog_chage_value).then(res => {
            this.sendGetUserData(this.props.name).then(res => {
                this.setState({ loading : false, data : res, dialog_open: false,  dialog_name : '', dialog_type : -1, dialog_value : null, dialog_chage_value : null });
            });
        });
    };

    customBodyRenderActions = (value, tableMeta, updateValue) => (
        <IconButton
            onClick={() => {

            }}
        >
            <EditIcon fontSize="small" />
        </IconButton>
    );

    render() {
        const purchase_columns = [
            { name: "orderid", label: "Orderid" },
            { name: "billcode", label: "Billcode" },
            { name: "price_code", label: "Price Code" },
            { name: "price", label: "Price" },            
            { name: "date", label: "Date" },

        ]
        const buff_columns = [
            { name: "buff_type", label: "Type", options: { sortDirection: 'asc' } },
            { name: "buff_level", label: "Level"},
            { name: "buff_seconds", label: "Seconds" },
            { name: "buff_active_date", label: "Active Date" },
            { name: "buff_last_update_time", label: "Last Update Time" },
        ];

        const emblem_columns = [
            { name: "emblem_idx", label: "Idx", options: { sortDirection: 'asc' } },
            { name: "emblem_is_get_reward", label: "Get Reward"},
            { name: "emblem_create_date", label: "Create Date" },
        ];

        const restaurant_columns = [
            { name: "restaurant_idx", label: "Idx", options: { sortDirection: 'asc' } },
            { name: "restaurant_food_lv1", label: "Food LV" },
            { name: "restaurant_food_lv2", label: "Food LV" },
            { name: "restaurant_food_lv3", label: "Food LV" },
            { name: "restaurant_food_lv4", label: "Food LV" },
            { name: "restaurant_food_lv5", label: "Food LV" },
        ]

        const item_columns = [
            { name: "item_idx", label: "Idx", options: { sortDirection: 'asc' } },
            { name: "item_count", label: "Count"},
        ];

        const fly_columns = [            
            { name: "fly_idx", label: "Idx", options: { sortDirection: 'asc' }},
            { name: "fly_piece", label: "Piece"},
            { name: "fly_star", label: "Star"},
            { name: "fly_lv", label: "Level"},
            { name: "fly_story", label: "Story"},
        ];

        const fly_slot_columns = [
            { name: "fly_slot_slot1", label: "Slot1", options: { sortDirection: 'asc' } },
            { name: "fly_slot_slot2", label: "Slot2" },
            { name: "fly_slot_slot3", label: "Slot3" },
            { name: "fly_slot_slot4", label: "Slot4" },
            { name: "fly_slot_slot5", label: "Slot5" },
            { name: "fly_slot_slot6", label: "Slot6" },
        ];

        const equipment_columns = [
            { name: "equipment_idx", label: "Idx", options: { sortDirection: 'asc' } },            
            { name: "equipment_level", label: "Level"},
            { name: "equipment_count", label: "Count"},
        ];

        const equipment_slot_columns = [            
            { name: "equipment_slot_weapon", label: "Weapon"},
            { name: "equipment_slot_armor", label: "Armor" },
            { name: "equipment_slot_bag", label: "Bag" }
        ];

        const vehicle_columns = [
            { name: "vehicle_idx", label: "Idx", options: {sortDirection: 'asc' }},
            { name: "vehicle_lv", label: "Level" },
            { name: "vehicle_skill_vehicle_idx", label: "SKill Idx" },
            { name: "vehicle_is_equip", label: "Equip Vehicle" }
        ]

        const skill_columns = [
            { name: "skill_idx", label: "Idx", options: {sortDirection: 'asc' } },
            { name: "skill_lv", label: "Level" }
        ]

        const skill_preset_columns = [
            { name: "idx", label: "Idx", options: {sortDirection: 'asc'}},
            { name: "skill_slot1", label: "Slot1"},
            { name: "skill_slot2", label: "Slot2"},
            { name: "skill_slot3", label: "Slot3"},
            { name: "skill_slot4", label: "Slot4"},
            { name: "skill_slot5", label: "Slot5"}
        ]

        const spice_columns = [
            { name: "spice_idx", label: "Idx", options: {sortDirection: 'asc' } },
            { name: "spice_grade", label: "Grade" },
            { name: "spice_lv", label: "Level" },
            { name: "spice_created_date", label: "Create Date" }
        ]

        const options = {
            print: false,
            responsive: 'scrollFullHeight',
            selectableRows: 'none',
            rowsPerPage : 10,
            rowsPerPageOptions : [10, 50, 100, 200, 500, 1000],
        };

        return (
            this.state.loading ? 
            <Wrapper>
                <Loader />
            </Wrapper> :
            <Grid container spacing={6}>
                <Grid item xs={5}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Card mb={6}>
                                <CardContent pb={0}>
                                    <Paper>
                                        <Typography variant="h6" gutterBottom>
                                            User Account
                                        </Typography>
                                        
                                        <TableWrapper>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            Useridx
                                                        </TableCell>
                                                        <TableCell>{this.state.data.useridx}</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                    
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            Userid
                                                        </TableCell>
                                                        <TableCell>{this.state.data.userid}</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            Name
                                                        </TableCell>
                                                        <TableCell>{this.state.data.name}</TableCell>
                                                        <TableCell>        
                                                            {/* <IconButton
                                                                onClick={() => {
                                                                    this.setState({ 
                                                                        dialog_open: true, 
                                                                        dialog_name : 'name', 
                                                                        dialog_type : 0,
                                                                        dialog_value : this.state.data.name,  
                                                                        dialog_chage_value : '',  
                                                                    });
                                                                }}
                                                            >
                                                                <EditIcon fontSize="small" />
                                                            </IconButton> */}
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            Platform
                                                        </TableCell>
                                                        <TableCell>{this.state.data.platform}</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            Level
                                                        </TableCell>
                                                        <TableCell>{this.state.data.level}</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            MaxStage
                                                        </TableCell>
                                                        <TableCell>{this.state.data.max_stage}</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                    
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            Last Login Time
                                                        </TableCell>
                                                        <TableCell>{this.state.data.logout_date}</TableCell>                                                        
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            Create Account Time
                                                        </TableCell>
                                                        <TableCell>{this.state.data.create_date}</TableCell>
                                                        <TableCell>        
                                                        </TableCell>
                                                    </TableRow>
                                                    
                                                </TableBody>
                                            </Table>
                                        </TableWrapper>
                                    </Paper>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}>
                            <Card mb={6}>
                                <CardContent pb={0}>
                                    <Paper>
                                        <Typography variant="h6" gutterBottom>
                                            User Resource
                                        </Typography>
                                        
                                        <TableWrapper>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
 
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            Gold
                                                        </TableCell>
                                                        <TableCell>{this.state.data.gold}</TableCell>
                                                        <TableCell>        
                                                            <IconButton
                                                                onClick={() => {
                                                                    this.setState({ 
                                                                        dialog_open: true, 
                                                                        dialog_name : 'gold', 
                                                                        dialog_type : 5,
                                                                        dialog_value : this.state.data.gold,  
                                                                        dialog_chage_value : 0,  
                                                                    });
                                                                }}
                                                            >
                                                                <EditIcon fontSize="small" />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            Diamond
                                                        </TableCell>
                                                        <TableCell>{this.state.data.diamond}</TableCell>
                                                        <TableCell>        
                                                            <IconButton
                                                                onClick={() => {
                                                                    this.setState({ 
                                                                        dialog_open: true, 
                                                                        dialog_name : 'diamond', 
                                                                        dialog_type : 6,
                                                                        dialog_value : this.state.data.diamond,  
                                                                        dialog_chage_value : 0,  
                                                                    });
                                                                }}
                                                            >
                                                                <EditIcon fontSize="small" />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            CarrotOil
                                                        </TableCell>
                                                        <TableCell>{this.state.data.carrot_oil}</TableCell>
                                                        <TableCell>        
                                                            <IconButton
                                                                onClick={() => {
                                                                    this.setState({ 
                                                                        dialog_open: true, 
                                                                        dialog_name : 'carrotoil', 
                                                                        dialog_type : 7,
                                                                        dialog_value : this.state.data.carrotoil,  
                                                                        dialog_chage_value : 0,  
                                                                    });
                                                                }}
                                                            >
                                                                <EditIcon fontSize="small" />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            Star
                                                        </TableCell>
                                                        <TableCell>{this.state.data.star}</TableCell>
                                                        <TableCell>        
                                                            <IconButton
                                                                onClick={() => {
                                                                    this.setState({ 
                                                                        dialog_open: true, 
                                                                        dialog_name : 'star', 
                                                                        dialog_type : 8,
                                                                        dialog_value : this.state.data.star,  
                                                                        dialog_chage_value : 0,  
                                                                    });
                                                                }}
                                                            >
                                                                <EditIcon fontSize="small" />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>

                                                </TableBody>
                                            </Table>
                                        </TableWrapper>
                                    </Paper>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}>
                            <Card mb={6}>
                                <CardContent pb={0}>
                                    <Paper>
                                        <Typography variant="h6" gutterBottom>
                                            User Character
                                        </Typography>
                                        
                                        <TableWrapper>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            Level
                                                        </TableCell>
                                                        <TableCell>{this.state.data.level}</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                    
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            Attack Level
                                                        </TableCell>
                                                        <TableCell>{this.state.data.atk_lv}</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            Hp Level
                                                        </TableCell>
                                                        <TableCell>{this.state.data.hp_lv}</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            Defense Level
                                                        </TableCell>
                                                        <TableCell>{this.state.data.def_lv}</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            Attack Speed Level
                                                        </TableCell>
                                                        <TableCell>{this.state.data.atk_speed_lv}</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            Critical Prop Level
                                                        </TableCell>
                                                        <TableCell>{this.state.data.crt_prob_lv}</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                    
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            Critical Defense Level
                                                        </TableCell>
                                                        <TableCell>{this.state.data.crt_defense_lv}</TableCell>                                                        
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            Double Critical Prob Level
                                                        </TableCell>
                                                        <TableCell>{this.state.data.double_crt_prob_lv}</TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            Double Critical Damage Level
                                                        </TableCell>
                                                        <TableCell>{this.state.data.double_crt_dmg_lv}</TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            Bottle Level
                                                        </TableCell>
                                                        <TableCell>{this.state.data.bottle_lv}</TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            Gas Level
                                                        </TableCell>
                                                        <TableCell>{this.state.data.gas_lv}</TableCell>
                                                    </TableRow>
                                                    
                                                </TableBody>
                                            </Table>
                                        </TableWrapper>
                                    </Paper>
                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item xs={7}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Card mb={6}>
                                <CardContent pb={0}>
                                    <Paper>
                                        <MUIDataTable 
                                            title={"Purchase List Table"} 
                                            data={this.state.data.purchase_list} 
                                            columns={purchase_columns}
                                            options={options}
                                        />
                                    </Paper>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card mb={6}>
                                <CardContent pb={0}>
                                    <Paper>
                                        <MUIDataTable 
                                            title={"Buff List Table"} 
                                            data={this.state.data.buff_list} 
                                            columns={buff_columns}
                                            options={options}
                                        />
                                    </Paper>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}>
                            <Card mb={6}>
                                <CardContent pb={0}>
                                    <Paper>
                                        <MUIDataTable 
                                            title={"Emblem Table"} 
                                            data={this.state.data.emblem_list} 
                                            columns={emblem_columns}
                                            options={options}
                                        />
                                    </Paper>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}>
                            <Card mb={6}>
                                <CardContent pb={0}>
                                    <Paper>
                                        <MUIDataTable 
                                            title={"Restaurant Table"} 
                                            data={this.state.data.restaurant_list} 
                                            columns={restaurant_columns}
                                            options={options}
                                        />
                                    </Paper>
                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        
                        <Grid item xs={12}>
                            <Card mb={6}>
                                <CardContent pb={0}>
                                    <Paper>
                                        <MUIDataTable 
                                            title={"Item List Table"} 
                                            data={this.state.data.item_list} 
                                            columns={item_columns}
                                            options={options}
                                        />
                                    </Paper>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={6}>
                            <Card mb={6}>
                                <CardContent pb={0}>
                                    <Paper>
                                        <MUIDataTable 
                                            title={"Fly List Table"} 
                                            data={this.state.data.fly_list} 
                                            columns={fly_columns}
                                            options={options}
                                        />
                                    </Paper>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={6}>
                            <Card mb={6}>
                                <CardContent pb={0}>
                                    <Paper>
                                        <MUIDataTable 
                                            title={"Fly SLot List Table"} 
                                            data={this.state.data.fly_slot_list} 
                                            columns={fly_slot_columns}
                                            options={options}
                                        />
                                    </Paper>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={6}>
                            <Card mb={6}>
                                <CardContent pb={0}>
                                    <Paper>
                                        <MUIDataTable 
                                            title={"Equipment List Table"} 
                                            data={this.state.data.equipment_list} 
                                            columns={equipment_columns}
                                            options={options}
                                        />
                                    </Paper>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={6}>
                            <Card mb={6}>
                                <CardContent pb={0}>
                                    <Paper>
                                        <MUIDataTable 
                                            title={"Equipment Slot List Table"} 
                                            data={this.state.data.equipment_slot_list} 
                                            columns={equipment_slot_columns}
                                            options={options}
                                        />
                                    </Paper>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}>
                            <Card mb={6}>
                                <CardContent pb={0}>
                                    <Paper>
                                        <MUIDataTable 
                                            title={"Vehicle List Table"} 
                                            data={this.state.data.vehicle_list} 
                                            columns={vehicle_columns}
                                            options={options}
                                        />
                                    </Paper>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}>
                            <Card mb={6}>
                                <CardContent pb={0}>
                                    <Paper>
                                        <MUIDataTable 
                                            title={"Skill List Table"} 
                                            data={this.state.data.skill_list} 
                                            columns={skill_columns}
                                            options={options}
                                        />
                                    </Paper>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}>
                            <Card mb={6}>
                                <CardContent pb={0}>
                                    <Paper>
                                        <MUIDataTable 
                                            title={"Skill Preset List Table"} 
                                            data={this.state.data.skill_preset_list} 
                                            columns={skill_preset_columns}
                                            options={options}
                                        />
                                    </Paper>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}>
                            <Card mb={6}>
                                <CardContent pb={0}>
                                    <Paper>
                                        <MUIDataTable 
                                            title={"Spice List Table"} 
                                            data={this.state.data.spice_list} 
                                            columns={spice_columns}
                                            options={options}
                                        />
                                    </Paper>
                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>
                </Grid>

                <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    open={this.state.dialog_open}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Edit</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h4" gutterBottom display="inline">
                                    { this.state.dialog_name }
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Divider my={3} />
                            </Grid>

                            <Grid item xs={5}>
                                <TextField
                                    disabled
                                    id="outlined-dense-logout_date"
                                    label={ this.state.dialog_name }
                                    margin="dense"
                                    variant="outlined"
                                    value={this.state.dialog_value}
                                />
                            </Grid>

                            <Grid item xs={1} style={{ textAlign: 'center', alignSelf: 'center' }}>
                                <ArrowRightAltIcon />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    id="outlined-dense-name"
                                    label={ this.state.dialog_name }
                                    name='dialog_chage_value'
                                    margin="dense"
                                    variant="outlined"
                                    value={this.state.dialog_chage_value}
                                    onChange={this.handleChange}
                                />
                            </Grid>
                        </Grid>

                        <Divider my={6} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogClose} color="primary">
                            CLOSE
                        </Button>
                        <Button onClick={this.handleDialogSave} variant="contained" color="primary">
                            SAVE
                        </Button>
                    </DialogActions>
                </Dialog>

            </Grid>
        );
    }
}

function KnightOfDeliveryGameUserSearchDetail(params) {
    var server = "DEV";
    if (Number(params.server) === 1) {
        server = "LIVE";
    }

    return (
        <React.Fragment>
            <Breadcrumbs aria-label="Breadcrumb">
                <Typography>Game User Search</Typography>
            </Breadcrumbs>

            <Spacer mt={2} />
            <Typography variant="h4" gutterBottom display="inline" color="error">
                {server}
            </Typography>

            <Spacer mt={2} />
            <Typography variant="h4" gutterBottom display="inline">
                {params.name}
            </Typography>

            <Divider my={6} />

            <LogBuyCashSearchDetailCard name={params.name} server={params.server} />

        </React.Fragment>
    );
}

export default KnightOfDeliveryGameUserSearchDetail;
