import React from "react";

import async from "../components/Async";

import {
  Cpu,
  Grid,
  Briefcase,
  Database
} from "react-feather";

const Default = async(() => import("../pages/Default"));
const Analytics = async(() => import("../pages/Analytics"));


const KnightOfDelivery_ServerManager = async(() => import("../pages/knightofdelivery/ServerManager"));
const KnightOfDelivery_GameManager = async(() => import("../pages/knightofdelivery/GameManager"));
const KnightOfDelivery_GameDataTable = async(() => import("../pages/knightofdelivery/GameDataTable"));
const KnightOfDelivery_GameUserSearch = async(() => import("../pages/knightofdelivery/GameUserSearch"));
const KnightOfDelivery_GameNotice = async(() => import("../pages/knightofdelivery/GameNotice"));
const KnightOfDelivery_GamePostManager = async(() => import("../pages/knightofdelivery/GamePostManager"));
const KnightOfDelivery_GameCouponManager = async(() => import("../pages/knightofdelivery/GameCouponManager"));
const KnightOfDelivery_GameEventManager = async(() => import("../pages/knightofdelivery/GameEventManager"));
const KnightOfDelivery_LogBuyCashChart = async(() => import("../pages/knightofdelivery/LogBuyCashChart"));
const KnightOfDelivery_LogBuyCashTable = async(() => import("../pages/knightofdelivery/LogBuyCashTable"));
const KnightOfDelivery_GameReport = async(() => import("../pages/knightofdelivery/GameReport"));


const commonRoutes = {
  id: "Default",
  path: "/",
  header: "Common",
  icon: <Grid />,
  children: [
    {
      path: "/",
      name: "Default",
      component: Default
    },
    {
      path: "/Analytics",
      name: "Analytics",
      component: Analytics
    },
  ]
};

const knightofdeliveryGameManagerRoutes = {
  id: "Game Manager",
  path: "/knightofdelivery/gamemanager",
  header: "Knight Of Delivery",
  icon: <Briefcase />,
  children: [
    {
      path: "/knightofdelivery/gamemanager",
      name: "Game Manager",
      component: KnightOfDelivery_GameManager
    },
    {
      path: "/knightofdelivery/gamemanager/gameDataTable",
      name: "Game Data Table",
      component: KnightOfDelivery_GameDataTable
    },
    {
      path: "/knightofdelivery/gamemanager/gameUserSearch",
      name: "Game User Search",
      component: KnightOfDelivery_GameUserSearch
    },
    {
      path: "/knightofdelivery/gamemanager/gameNotice",
      name: "Game Notice",
      component: KnightOfDelivery_GameNotice
    },
    {
      path: "/knightofdelivery/gamemanager/gameEvent",
      name: "Game Event Manager",
      component: KnightOfDelivery_GameEventManager
    },
    {
      path: "/knightofdelivery/gamemanager/postManager",
      name: "Game Post Manager",
      component: KnightOfDelivery_GamePostManager
    },
    {
      path: "/knightofdelivery/gamemanager/gameCouponManager",
      name: "Game Coupon Manager",
      component: KnightOfDelivery_GameCouponManager
    },
    {
      path: "/knightofdelivery/gamemanager/gameReport",
      name: "Game Report",
      component: KnightOfDelivery_GameReport
    }
  ]
}

const knightofdeliveryServerManagerRoutes = {
  id: "Server Manager",
  path: "/knightofdelivery/servermanager",
  icon: <Cpu />,
  children: [
    {
      path: "/knightofdelivery/servermanager",
      name: "Server Manager",
      component: KnightOfDelivery_ServerManager
    }
  ]
}

const knightofdeliveryGameLogsRoutes = {
  id: "Game Logs",
  path: "/knightofdelivery/gamelogs",
  icon: <Database />,
  children: [
    {
      path: "/knightofdelivery/gamelogs/logBuyCashChart",
      name: "log Buy Cash Chart",
      component: KnightOfDelivery_LogBuyCashChart
    },
    {
      path: "/knightofdelivery/gamelogs/logBuyCashTable",
      name: "log Buy Cash Table",
      component: KnightOfDelivery_LogBuyCashTable
    }
  ]
}


export const dashboard = [
  commonRoutes,  
  knightofdeliveryGameManagerRoutes,
  knightofdeliveryServerManagerRoutes,
  knightofdeliveryGameLogsRoutes,
];


var json_data = {}

if (sessionStorage.length !== 0) {
  json_data = JSON.parse(sessionStorage.getItem('userInfo'))
}

var user_export = [];
if (1 <= json_data['grade']) {
  user_export = [
    knightofdeliveryGameManagerRoutes,
    knightofdeliveryServerManagerRoutes,
    knightofdeliveryGameLogsRoutes,
  ];
}

if (2 <= json_data['grade']) {
  user_export = [
    knightofdeliveryGameManagerRoutes,
    knightofdeliveryServerManagerRoutes,
    knightofdeliveryGameLogsRoutes,
  ];
}
if (5 <= json_data['grade']) {
  user_export = [
    knightofdeliveryGameManagerRoutes,
    knightofdeliveryServerManagerRoutes,
    knightofdeliveryGameLogsRoutes,
  ];
}


export default user_export
// export default [
//   commonRoutes,  
//   jobchangersManagerRoutes,
//   jobchangersServerManagerRoutes,
//   jobchangersCouponManagerRoutes,
//   jobchangersLogsRoutes,
//   knightofdeliveryGameManagerRoutes,
//   knightofdeliveryServerManagerRoutes,
//   knightofdeliveryGameLogsRoutes,
//   catmartfactoryRoutes,
//   tileRPGGameManagerRoutes,
//   tileRPGServerManagerRoutes,
//   tileRPGGameLogsRoutes,
//   tileRPGGameManagerDevRoutes,
//   monstereaterManagerRoutes,
//   monstereaterServerManagerRoutes,
//   monstereaterLogsRoutes,
//   moneyHeroRoutes,
//   hamsterbagfactoryRoutes,
//   tycoonpuppycafeRoutes,
//   bearbakeryRoutes,
//   tycooncatRoutes,
//   tycooncookieRoutes,
//   hamsterRoutes,
//   newlifeRoutes,
//   superretroRoutes,
//   mergeVillageRoutes,
//   justshootingRoutes,  
// ];
