import axios from "axios";

import React from "react";

import styled from "styled-components";

import Link from '@mui/material/Link';

import {
  Grid,
  Input,
  InputLabel,
  Button as MuiButton,
  Paper,
  Typography
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { request_url } from "../../index";

const Button = styled(MuiButton)(spacing);

const Wrapper = styled(Paper)`
  padding: ${props => props.theme.spacing(6)}px;

  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(10)}px;
  }
`;



class SignIn extends React.Component {
    state = { 
		email : '',
		name : '',
		grade : 0,

		password : ''
    };

    componentDidMount() {
    }

    sendSignIn = async (email, password) => {
        const options = {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Accept': 'application/json',                  
            },
            data: {
                "email" : email,
                "password" : password,
            },
            url: request_url + '/SignIn',
        };

        const response = await axios(options);
        if (response.status === 200) {
			if (!response.data) {
				alert("로그인 실패. \n이메일 또는 비밀번호를 확인해주세요")
				return null;
			}
            return response.data;
        }

        return null;
	}
	
	handleChange = (event) => {
        this.setState({ [event.target.name] : event.target.value });
	}
	
	handleClickButton = () => {
		this.sendSignIn(this.state.email, this.state.password).then(res => {
			if (res.length === 0) {
				alert("로그인 실패. 아이디 또는 비밀번호를 확인해주세요.")
				return null;
			}
			
			sessionStorage.setItem(
				'userInfo',
				JSON.stringify({
					'email' : this.state.email,
					'name' : res['name'],
					'grade' : res['grade'],
				})
			);
			
			this.setState({name : res['name'], grade : res['grade']})
			window.location.reload();			
		});
	}

    render() {
        return (
			<Wrapper>
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<Typography component="h1" variant="h4" align="center" gutterBottom>
							MAF GAMES
						</Typography>
						<Typography component="h2" variant="body1" align="center">
							Sign in to your account to continue
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<InputLabel htmlFor="email">Email Address</InputLabel>
						<Input fullWidth id="email" name="email" autoComplete="email" autoFocus onChange={this.handleChange} />
					</Grid>
					<Grid item xs={12}>
						<InputLabel htmlFor="password">Password</InputLabel>
						<Input
							fullWidth
							name="password"
							type="password"
							id="password"
							autoComplete="current-password"
							onChange={this.handleChange}
							onKeyPress={(event) => {
								if (event.key === 'Enter') {
									this.handleClickButton();
								}
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<Button
							fullWidth
							variant="contained"
							color="primary"
							onClick={this.handleClickButton}
						>
							Sign in
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Button
							fullWidth
							variant="contained"
							onClick={this.handleClickButton}
						>
							Google
						</Button>
					</Grid>

					<Grid item xs={12}>
						<Typography variant="body2" color="secondary" align="center" >
							{'Copyright © '}
							<Link color="inherit" href="https://mui.com/">
							MafGames
							</Link>{' '}
							{new Date().getFullYear()}
							{'.'}
						</Typography>
					</Grid>
				</Grid>
			</Wrapper>
        );
    }
}

export default SignIn;
