import axios from "axios";

import moment from "moment";

import React from "react";

import styled from "styled-components";

import { NavLink as RouterNavLink } from "react-router-dom";

import { DatePicker } from "@material-ui/pickers";

import FullCalendar from "@fullcalendar/react";

import dayGridPlugin from "@fullcalendar/daygrid";

import interactionPlugin from "@fullcalendar/interaction";

import MuiSelect from 'react-select';

import FormGroup from '@mui/material/FormGroup';

import FormControlLabel from '@mui/material/FormControlLabel';

import {
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField as MuiTextField,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Button as MuiButton,
    Link,
    Breadcrumbs as MuiBreadcrumbs,
    Divider as MuiDivider,
    Typography
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { CheckBox } from "@material-ui/icons";

const NavLink = React.forwardRef((props, ref) => (
  <RouterNavLink innerRef={ref} {...props} />
));

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Select = styled(MuiSelect)(spacing);

const TextFieldSpacing = styled(MuiTextField)(spacing);

const TextField = styled(TextFieldSpacing)`
  width: 100%;
`;

function numberWithCommas(x) {
    let num = Math.round(x * 100) / 100
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class DefaultCard extends React.Component {
    state = { events : [], open : false, startDate : '2021-01-01', endDate : '2021-01-01', content : '', color : '#616161', loading : false, currentPageDateInfo : {}, eventSchedules : [], eventPurchaseContainer : {}, openDialog : false, filters : Number.MAX_SAFE_INTEGER }

    urls = [
        { name : '봉식', url : 'https://common-server-248408.uc.r.appspot.com/MoneyHero/GetLogBuyCashDayTotalSumDataList', color : '#FFCACC' },
        { name : '겜망', url : 'https://common-server-248408.uc.r.appspot.com/TileRPG/GetLogBuyCashDayTotalSumDataList', color : '#FFCACC' },
        { name : '전직자들', url : 'https://common-server-248408.uc.r.appspot.com/TheChangers/GetLogBuyCashDayTotalSumDataList', color : '#FFCACC' },
        { name : '곰베이커리', url : 'https://common-server-248408.uc.r.appspot.com/BearBakery/GetLogBuyCashDayTotalSumDataList', color : '#DBC4F0' },
        { name : '햄가방', url : 'https://common-server-248408.uc.r.appspot.com/Hamsterbagfactory/GetLogBuyCashDayTotalSumDataList', color : '#DBC4F0' },
        { name : '쿠키', url : 'https://common-server-248408.uc.r.appspot.com/TycoonCookie/GetLogBuyCashDayTotalSumDataList', color : '#DBC4F0' },
        { name : '고양이', url : 'https://common-server-248408.uc.r.appspot.com/TycoonCat/GetLogBuyCashDayTotalSumDataList', color : '#DBC4F0' },
        { name : '강아지카페', url : 'https://common-server-248408.uc.r.appspot.com/TycoonPuppyCafe/GetLogBuyCashDayTotalSumDataList', color : '#DBC4F0' },
        { name : '고양이마트', url : 'https://common-server-248408.uc.r.appspot.com/CatMart/GetLogBuyCashDayTotalSumDataList', color : '#DBC4F0' },
    ]

    componentDidMount() {
        this.sendGetGameScheduleList().then(res => {
            this.setEventSchedule(res)
        });
    }

    updateEventList = (startTime, endTime) => {
        this.sendGetGameScheduleList().then(res => {
            this.setEventSchedule(res)

            this.setState({ loading : true })
            this.sendGetLogBuyCashDayTotalSumDataListWithUrls(startTime, endTime).then(() => {
                this.setEventPurchase(this.state.eventPurchaseContainer)
                this.setState({ loading : false })
            }).catch(error => {
                alert(error.message);
            });
        }).catch(error => {
            alert(error.message);
        });
    }

    setEvent = (inEventSchedules, inEventPurchaseContainer) => {
        const event_result = []
        event_result.push(Object.values(inEventPurchaseContainer))
        event_result.push(inEventSchedules)
        this.setState({ events : event_result.flat(), eventSchedules : inEventSchedules, eventPurchaseContainer : inEventPurchaseContainer })
    }

    setEventSchedule = (eventList) => {
        this.setEvent(eventList, this.state.eventPurchaseContainer)
    }

    setEventPurchase = (eventContainer) => {
        this.setEvent(this.state.eventSchedules, eventContainer)
    }

    sendGetLogBuyCashDayTotalSumDataListWithUrls = async (startDate, endDate) => {
        const eventContainer = this.state.eventPurchaseContainer;

        for(var i = 0; i < this.urls.length; i++) 
        {
            if ((this.state.filters & Math.pow(2, i)) === 0) {
                continue
            }
            const elementUrl = this.urls[i]['url']
            const elementName = this.urls[i]['name']
            const elementColor = this.urls[i]['color']

            const res = await this.sendGetLogBuyCashDayTotalSumDataList(elementUrl, startDate, endDate)

            res.forEach(function(element) {
                const data = {title: '[' + elementName + '] ' + numberWithCommas(element['sum']), date: element['date'], color: elementColor}
                eventContainer[element['date'] + '_' + elementName] = data
            });

            this.setEventPurchase(eventContainer)
        }
    }

    sendGetLogBuyCashDayTotalSumDataList = async (inUrl, startDate, endDate) => {
        const options = {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Accept': 'application/json',                  
            },
            data: {
                'startDate' : startDate,
                "endDate" : moment(endDate).format('YYYY-MM-DD')
            },
            url: inUrl,
        };

        const response = await axios(options);
        if (response.status === 200) {
            return response.data
        }

        return [];
    }

    sendGetGameScheduleList = async () => {
        const response = await axios.get('https://tilerpg.cs.mafrpgserver.net/GetGameScheduleList');
        if (response.status === 200) {
            return response.data
        }

        return {};
    }

    sendAddGameSchedule = async (title, start, end, color) => {
        const options = {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Accept': 'application/json',                  
            },
            data: {
                "title" : title,
                "start" : start,
                "end" : end,
                "color" : color
            },
            url: 'https://tilerpg.cs.mafrpgserver.net/AddGameSchedule',
        };

        const response = await axios(options);
        if (response.status === 200) {
            return response.data
        }

        return null;
    }

    sendUpdateGameSchedule = async (idx, days) => {
        const options = {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Accept': 'application/json',                  
            },
            data: {
                "idx" : idx,
                "days" : days
            },
            url: 'https://tilerpg.cs.mafrpgserver.net/UpdateGameSchedule',
        };

        const response = await axios(options);
        if (response.status === 200) {
            return response.data
        }

        return null;
    }

    handleDialogOpen = (arg) => {
        this.setState({ open: true, startDate : arg['dateStr'], endDate : arg['dateStr'], content : '' });
    };
    
    handleDialogClose = () => {
        this.setState({ open: false });
    };

    handleStartDateChange = (date) => {
        this.setState({ startDate : moment(date).format('YYYY-MM-DD') });
    };

    handleEndDateChange = (date) => {
        this.setState({ endDate : moment(date).format('YYYY-MM-DD') });
    };

    handleContentChange = (event) => {
        this.setState({ content : event.target.value });
    }

    handleColorChange = (event) => {
        this.setState({ color : event.value });
    }

    handleClickOkButton = () => {
        this.sendAddGameSchedule(this.state.content, this.state.startDate, this.state.endDate, this.state.color).then(res => {
            this.sendGetGameScheduleList().then(res => {
                this.setEventSchedule(res);
                this.setState({ open: false });
            });
        });
    };

    handleOnClickReloadButton = () => {
        var startTime = this.state.currentPageDateInfo.startStr.split('T')[0];
        var endTime = this.state.currentPageDateInfo.endStr.split('T')[0];
        this.updateEventList(startTime, endTime)
    }

    handleEventDrop = (info) => {
        this.sendUpdateGameSchedule(info.event.extendedProps.idx, info.delta.days).then(res => {
        });
    }

    handleMonthChange = (payload) => {
        this.setState({ currentPageDateInfo : payload });
    }

    renderReloadButton = () => {
        if (!this.state.loading) {
            return 
            //(
                // <Button fullWidth mb={2.5} variant="contained" color="primary" onClick={this.handleOnClickReloadButton}>
                //     매출 갱신
                // </Button>
            //    )
        }
        else {
            return (
                <Button fullWidth mb={2.5} variant="contained" color="primary" onClick={this.handleOnClickReloadButton} disabled={true}>
                    로딩중...
                </Button>)
        }
    }

    handleOnChangeDialogCheckbox = (index, event, checked) => {
        var state = this.state.filters
        if (checked){ 
            state = state ^ Math.pow(2, index)
        }
        else{
            state = state & ~Math.pow(2, index)
        }
        this.setState({ filters : state })
    }

    renderFilterDialog = () => {
        if (this.state.openDialog === false) {
            return
        }

        const contents = () => {
            const results = []
            for(let i = 0; i < this.urls.length; i++) {
                results.push(
                <FormControlLabel key={i} 
                    control={<CheckBox checked={(this.state.filters & Math.pow(2, i)) !== 0}
                    onChange={(event, checked) => this.handleOnChangeDialogCheckbox(i, event, checked)}/>} 
                    label={this.urls[i]['name']} />
                )
            }

            return results
        }

        return (
            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={this.state.openDialog}
                onEnter={this.handleDialogOnEnter}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">매출 갱신 필터링</DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <FormGroup>
                            {contents()}
                        </FormGroup>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleOnClickFilterDialogOkButton} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    handleOnClickFilterButton = () => {
        this.setState({ openDialog : true })
    }

    handleOnClickFilterDialogOkButton = () => {
        this.setState({ openDialog : false })
    }

    render() {
        return (
            <Card mb={6}>
                <CardContent p={6}>
                    <Grid container spacing={2}>
                        {/* <Grid item>
                            <Button fullWidth mb={2.5} variant="contained" color="primary" onClick={this.handleOnClickFilterButton}>
                                +
                            </Button>
                        </Grid> */}
                        <Grid item>
                            {this.renderReloadButton()}
                        </Grid>
                    </Grid>
                    <FullCalendar
                        plugins={[dayGridPlugin, interactionPlugin]}
                        events={this.state.events}
                        dayMaxEvents={true}
                        editable={true}
                        droppable={true}
                        headerToolbar={{
                            left: 'dayGridMonth,dayGridWeek,dayGridDay today',
                            center: 'title',
                            right: 'prevYear,prev,next,nextYear'
                        }}
                        dateClick={this.handleDialogOpen}
                        eventDrop={this.handleEventDrop}
                        datesSet={this.handleMonthChange}
                    />

                    {this.renderFilterDialog()}

                    <Dialog
                        fullWidth={true}
                        maxWidth="sm"
                        open={this.state.open}
                        onEnter={this.handleDialogOnEnter}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">Add Schedule</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={1}>
                                <Grid item xs={3}>
                                    <DatePicker
                                        margin="dense"
                                        variant="inline"
                                        inputVariant="outlined"
                                        label="Start Date"
                                        value={this.state.startDate}
                                        autoOk={true}
                                        onChange={this.handleStartDateChange}
                                        format="yyyy-MM-dd"
                                        InputProps={{ style: { fontSize: 14 } }}
                                        fullWidth={true}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <DatePicker
                                        margin="dense"
                                        variant="inline"
                                        inputVariant="outlined"
                                        label="End Date"
                                        value={this.state.endDate}
                                        autoOk={true}
                                        onChange={this.handleEndDateChange}
                                        format="yyyy-MM-dd"
                                        InputProps={{ style: { fontSize: 14 } }}
                                        fullWidth={true}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                </Grid>
                                <Grid item xs={3}>
                                    <Select 
                                        mt={2.1}
                                        options={[{ value: '#616161', label: '검정' }, { value: '#F34E29', label: '빨강' }, { value: '#8D16DB', label: '파랑' }]}
                                        defaultValue={{ value: '#616161', label: '검정' }}
                                        onChange={this.handleColorChange}
                                        styles={{ menu: styles => ({ ...styles, zIndex: 1000 }) }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label='Content'
                                        multiline
                                        rows="10"
                                        variant="outlined"
                                        value={this.state.content}
                                        onChange={this.handleContentChange}
                                        InputProps={{ style: { fontSize: 14.5 } }}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleDialogClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={this.handleClickOkButton} color="primary">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>

                </CardContent>
            </Card>
        );
    }
}

function Default() {
    return (
        <React.Fragment>
            <Typography variant="h4" gutterBottom display="inline">
                Default
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link component={NavLink} exact to="/">
                    Default
                </Link>
            </Breadcrumbs>

            <Divider my={6} />

            <DefaultCard />

        </React.Fragment>
    );
}

export default Default;
