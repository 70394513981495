import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { dashboard as dashboardRoutes } from "./index";

import DashboardLayout from "../layouts/Dashboard";

import AuthLayout from "../layouts/Auth";
import Default from "../pages/Default";
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";

import KnightOfDeliveryGameUserSearchDetail from "../pages/knightofdelivery/GameUserSearchDetail";

const childRoutes = (Layout, routes) =>
    routes.map(({ children, path, component: Component }, index) =>
        children ? (
            // Route item with children
            children.map(({ path, component: Component }, index) => (
                <Route
                    key={index}
                    path={path}
                    exact
                    render={props => (
                        <Layout>
                            <Component {...props} />
                        </Layout>
                    )}
                />
            ))
        ) : (
            // Route item without children
            <Route
                key={index}
                path={path}
                exact
                render={props => (
                    <Layout>
                        <Component {...props} />
                    </Layout>
                )}
            />
        )
);

const Routes = () => (
    <Router>
        <Switch>
            { sessionStorage.getItem('userInfo') ? childRoutes(DashboardLayout, dashboardRoutes) : null }

            { sessionStorage.getItem('userInfo') ?
                <Route 
                    path="/knightofdelivery/gamemanager/gameUserSearch/:name/:server"
                    render={(props) => (                        
                        <DashboardLayout>
                            <KnightOfDeliveryGameUserSearchDetail name={props.match.params.name} server={props.match.params.server} />
                        </DashboardLayout>
                    )}
                /> : null
            }
            
            <Route
                path="/SignUp"
                render={() => (
                    <AuthLayout>
                        <SignUp />
                    </AuthLayout>
                )}
            />

            <Route
                path="/"
                render={() => (
                    <AuthLayout>
                        <SignIn />
                    </AuthLayout>
                )}
            />

        </Switch>
    </Router>
);

export default Routes;
