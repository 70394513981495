import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { createRoot } from 'react-dom/client'
import App from './App';

import { Provider } from 'react-redux';
import store from './redux/store/index';

// ReactDOM.render(
//   <Provider store={store}>
//     <App />
//   </Provider>, document.getElementById('root'));

createRoot(document.getElementById('root')).render(<Provider store={store}> <App /></Provider>);


export const request_url = 'https://knightofdelivery-admin.mafgameduo.com/';
//export const request_url = 'http://127.0.0.1:5001/'